import React, { createContext, useEffect, useState } from 'react'
import { injected } from 'src/connectors'
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-toastify'
import { SUPPORTED_WALLETS } from 'src/connectors'
import { ACTIVE_NETWORK } from 'src/constants'
import axios from 'axios'
import apiConfig from 'src/APIconfig/ApiConfig'
import { getWeb3Obj } from 'src/utils'
export const UserContext = createContext()
const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem('userAddress', userAddress)
  } else {
    sessionStorage.removeItem('userAddress')
  }
}

export default function AuthProvider(props) {
  const { activate, account, deactivate, chainId } = useWeb3React()
  const [userData, setUserData] = useState({})
  const [yourWalletBalance, setYourWalletBalance] = useState(0)
  const [openNetwork, setOpenNetwork] = useState(false)
  const [isUserBlocked, setIsUserBlocked] = useState(false)
  const connectWalletHandler = (data) => {
    try {
      const connector = data?.connector

      if (connector && connector?.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          window.localStorage.removeItem('walletName')
          toast.error(JSON.stringify(error.message))
          window.localStorage.removeItem('walletName')
          activate(connector)
        }
      })
    } catch (error) {
      console.log('ERROR', error)
      toast.error(JSON.stringify(error.message))
    }
  }
  const deactivateWalletHandler = async () => {
    try {
      deactivate()
      window.localStorage.removeItem('walletName')
      sessionStorage.removeItem('userAddress')
    } catch (error) {
      console.log(error)
    }
  }

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + ACTIVE_NETWORK.toString(16) }],
      })
    } catch (error) {
      console.log('ERROR', error)

      toast.warn(error.message)
      // if (error.code === 4902) {
      //   addNetworkHandler();
      // }
    }
  }

  const walletConnectApiHandler = async (address) => {
    try {
      const res = await axios({
        method: 'POST',
        url: apiConfig.connectWallet,
        data: {
          walletAddress: address,
        },
      })
      if (res.data.responseCode === 200) {
        getProfileHandler(res?.data?.result?.token)
        window.sessionStorage.setItem('accessToken', res?.data?.result?.token)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getProfileHandler = async (token) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.getProfile,
        headers: {
          token: token,
        },
      })
      if (res.data.responseCode === 200) {
        setUserData(res.data.result)
      } else if (res.data.responseCode === 403) {
        setIsUserBlocked(true)
      }

    } catch (error) {
      if (error?.response?.data?.responseCode === 403) {
        setIsUserBlocked(true)
      }
      console.log(error)
    }
  }
  const getUserbalce = async () => {
    const web3 = await getWeb3Obj()
    const balance = await web3.eth.getBalance(account)
    const balanceImETH = await web3.utils.fromWei(balance)
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2))
  }
  useEffect(() => {
    if (account) {
      walletConnectApiHandler(account)
      getUserbalce()
    }
  }, [account])

  useEffect(() => {
    if (window.localStorage.getItem('walletName')) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.localStorage.getItem('walletName'),
      )

      if (selectectWalletDetails && selectectWalletDetails[0]?.data) {
        connectWalletHandler(selectectWalletDetails[0]?.data)
      }
    }
  }, [])

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler()
        }
      }
    }
  }, [chainId, account])
  let data = {
    userData,
    openNetwork,
    yourWalletBalance,
    isUserBlocked,
    updateUser: (account) => {
      setSession(account)
    },
    connectWallet: (data) => connectWalletHandler(data),
    deactivateWallet: () => deactivateWalletHandler(),
    getProfileHandler: (data) => getProfileHandler(data),
    setOpenNetwork: (data) => setOpenNetwork(data),
  }

  useEffect(() => {
    const userAddress = localStorage.getItem('userAddress')
    if (userAddress) {
      data.connectWallet()
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    data.updateUser(account)
  }, [account]) //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  )
}
