export const NetworkContextName = 'NETWORK'
export const ACTIVE_NETWORK = 4
export const tokenContract = '0xE4861c8C1A80250e1e280F7F7bbf84736146b867'
export const MarketplaceContract = '0xf5DE7F4Ee0C4063a1e6f139fEa2eB92c2D153653'
export const NftTokenAddress = '0x4846666e4013A48647be98AF3FDE33251e679bd2'
export const EngineAddress = '0x46b5bAEe1e915C75aE3AF9221beF6a534033d04D'
export const RPC_URL =
  'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'

export const RPC_URL_ETH = 'https://rinkeby.infura.io/v3/'

