const baseurl = "https://node-metahorse.mobiloitte.com";

let user = `${baseurl}/api/v1/user`;
const url = `${baseurl}/api/v1`;
const nft = `${url}/nft`;
const offer = `${url}/offer`;
const static1 = `${url}/static`;
const WhiteList = `${url}/whiteList`;
const horse = `${url}/horse`;
const order = `${url}/order`;
const admin = `${url}/admin`
const apiConfig = {
  //USER
  connectWallet: `${user}/connectWallet`,
  contactUs: `${user}/contactUs`,

  getProfile: `${user}/getProfile`,
  updateProfile: `${user}/updateProfile`,
  likeDiklikeNFT: `${user}/likeDislikeNFT`,
  buyOrder: `${user}/buyOrder`,
  mylikeNFT: `${user}/mylikeNFT`,
  activityList: `${user}/activityList`,
  mylistNft: `${nft}/mylistNft`,
  mySoldNft: `${nft}/mySoldNft`,

  //ORDER
  listOrder: `${url}/order/listOrder`,
  listAllOrder: `${url}/order/listAllOrder`,
  viewOrder: `${url}/order/viewOrder`,
  topTenUserByParticularOrder: `${url}/order/topTenUserByParticularOrder`,
  resaleOrder: `${url}/order/resaleOrder`,
  cancelOrder: `${url}/order/cancelOrder`,
  orderSaleList: `${url}/order/orderSaleList`,

  //offer
  createOffer: `${offer}/createOffer`,
  listOffer: `${offer}/listOffer`,
  cancelOffer: `${offer}/cancelOffer`,
  sellOffer: `${offer}/sellOffer`,

  //STATICCONTENT
  StaticContent: `${static1}/StaticContent`,

  //WHITELIST
  userWhitelistRequest: `${WhiteList}/userWhitelistRequest`,

  //filter
  horseFilters: `${horse}/horseFilters`,

  //pricehistory
  priceHistory: `${order}/priceHistory`,

  //eventList
  upcomingEventByParticaularHorse: `${user}/upcomingEventByParticaularHorse`,
  likeDislikeEvent: `${user}/likeDislikeEvent`,
  commentOnEvent: `${user}/commentOnEvent`,
  likeDislikeEventComment: `${user}/likeDislikeEventComment`,
  commentReplyOnEvent: `${user}/commentReplyOnEvent`,
  EventWinnerReportByParticaularHorse: `${user}/EventWinnerReportByParticaularHorse`,
  careerStatistics: `${user}/careerStatistics`,
  statistics: `${user}/statistics`,
  graphStatistics: `${user}/graphStatistics`,

  //achievements
  listAchievement: `${admin}/listAchievement`,


};

export default apiConfig;
